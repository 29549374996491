// Función para verificar si dos fechas son iguales (sin tener en cuenta la hora)
const isEqual = (date1, date2) => {
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );
  }

// Función para obtener la fecha de ayer
const toYesterday = (date) => {
    const yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }

// Función para obtener la fecha de mañana
const toMorrow = (date) => {
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

const getHumanReadDate = (timestamp, withLive = false) => {
    const dateNow = new Date();
    const timestampToDate = new Date(timestamp);

    // Compara las fechas
    if (isEqual(dateNow, timestampToDate)) {
        return (withLive) ? 'En Vivo' : 'Hoy';
    } else if (isEqual(toYesterday(dateNow), timestampToDate)) {
        return 'Ayer'
    } else if (isEqual(toMorrow(dateNow), timestampToDate)) {
        return 'Mañana'
    } else {
        const fechaSplit = timestampToDate.toLocaleDateString('es-AR').split('/');

        if(fechaSplit.length > 0){
            return fechaSplit[0] + '/' + fechaSplit[1];
        } else {
            return '';
        }
    }
}

const hasPassed24Hours = (endHourUnixTime = 0, fechaInicio = "") => {
  const now = new Date();
  const oneDayInMilliseconds = telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000;
  return fechaInicio.toLowerCase() === "ayer" && now - endHourUnixTime * 1000 > oneDayInMilliseconds;
};

export { getHumanReadDate, hasPassed24Hours }
