<template>
  <div>
    <div class="wrapper guide-traditional">
      <header class="main-header top-content">
        <div class="top-header">
          <div class="top-header-top">
            <h1 class="section-title">Guía de canales</h1>
          </div>
          <GuideTradDetail
            :program="focusedProgram"
            :startTime="startTime"
            :disable-on-left-side="onTheLeftSide"
            :disableOnRightSide="onTheRightSide"
            :is-live="isLive"
            @scroll-left="onScrollLeft"
            @scroll-right="onScrollRight"
            @go-to-day="goToDay"
            @scroll-to-channel="onScrollToChannel"
          />
        </div>
      </header>

      <main class="content" role="main">
        <div class="rail-guide" id="guide-screen">
          <GuideTraditionalMain
            :ref="'guideTraditionalMain'"
            :channels="getChannels"
            :focus-on-channel-number="focusOnChannel"
            :focus-on-time="getFocusOnTime"
            :date-in-timeline="getDateTime"
            :scrolled-channel="getScrolledChannel"
            :pixels-in-ten-minutes="getPixelsInTenMinutes"
            :to-check-parental-control="isBlocked"
            @blur="onBlur"
            @program-focus="onProgramFocus"
            @page-turn="onPageTurn"
            @live-refresh="onLiveRefresh"
          />
        </div>
      </main>
    </div>
    <Loader v-if="showFullLoader" />
  </div>
</template>

<script>
const CANT_INICIAL = 4;
const TIEMPO_SCROLL_HORIZONTAL = 1 * 60 * 60 * 1000;

import * as epg from "@/observables/epg";
import { nextTick } from "vue";
import * as dateHelper from "@/helpers/dateHelper.js";
import * as guideTradHelpers from "@/helpers/guideTradHelpers.js";
import { blockAsset } from "@/helpers/parentalControl.js";

export default {
  name: "GuideTrad",

  components: {
    Loader: () => import("@/components/Loader.vue"),
    LoaderScroll: () => import("@/components/Loader-scroll.vue"),
    GuideTraditionalMain: () =>
      import(
        "@/modules/guide/components/GuideTraditionalMain/GuideTraditionalMain.vue"
      ),
    GuideTradDetail: () => import("@/components/GuideTradDetail.vue"),
    GuideTradButtons: () => import("@/components/GuideTradButtons.vue"),
  },

  data() {
    return {
      dom: {
        gridContainer: null,
      },
      listChannel: [],
      channels: [],
      filters: {
        channelIds: [],
        canalesText: [],
        rango: {
          desde: 0,
          hasta: CANT_INICIAL,
        },
      },
      filterChannels: [],
      filterChannelsText: "",
      showFullLoader: true,
      isScroll: false,
      maxDaysBack: 1,
      maxDaysForward: 2,
      focusOnChannel: 0,
      time: null,
      focusedProgram: {
        idAsset: "",
        title: "Sin información",
        time: Date.now(),
        poster: "",
        channel: {
          logo: "",
          lcn: "",
        },
      },
      activatedEvents: false,
      focusOnTime: Date.now(),
      refreshTimeId: null,
      reactiveDetector: 0,
      pixelsInTenMinutes: 0,
      timeLineLeftPixels: 0,
      focusOnProgramIdAsset: 0,
      leftSide: false,
      rightSide: false,
      topSide: false,
      bottomSide: false,
      startTime: Date.now(),
      endTime: null,
      showModalPin: false,
      scrolledChannel: null,
      resizeObserver: null,
      horizontalScrollCounter: 0,
      isLive: true,
      firstAccess: true,
    };
  },

  created() {
    // seguir con guia series y peliculas copiar logica de tv
    let self = this;
    self.changeCursor(true);
  },

  mounted() {
    let self = this;

    const filters = {
      rango: {
        desde: 0,
        hasta: 1000,
      },
    };

    document.body.style.overflow = "hidden";

    self.resizeObserver = new ResizeObserver((element) => {
      //console.log("Size changed");
      self.onResizeChanges();
    });

    self.$epg.update().then((r) => {
      self.listChannel = r;
      self.focusOnChannel = 1;
      self.$nextTick(() => {
        const body = document.getElementById("tplay");
        self.pixelsInTenMinutes = guideTradHelpers.calcPixelsInTenMinutes();
        self.resizeObserver.observe(body);
        self.showFullLoader = false;
        self.changeCursor(false);
      });
    });
  },

  computed: {
    getFocusOnTime() {
      return this.focusOnTime;
    },

    getTextDetail() {
      return "texto prueba";
    },
    readableDateInfo() {
      return "fecha prueba";
    },
    bgImage() {
      "";
    },
    getChannels() {
      if (this.listChannel) {
        return this.listChannel;
      } else {
        return [];
      }
    },
    onTheLeftSide() {
      const leftMargin =
        Date.now() -
        this.maxDaysBack * 24 * 60 * 60 * 1000 -
        telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000;
      const prevPosition =
        this.startTime -
        telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000;
      if (prevPosition <= leftMargin) {
        return true;
      } else {
        return false;
      }
    },
    onTheRightSide() {
      const rightMargin =
        Date.now() + (this.maxDaysForward * 24 + 2) * 60 * 60 * 1000;
      const nextPosition = this.endTime;
      if (nextPosition >= rightMargin) {
        return true;
      } else {
        return false;
      }
    },
    getScrolledChannel() {
      return this.scrolledChannel;
    },
    getPixelsInTenMinutes() {
      //console.log('getPixelsInTenMinutes', this.pixelsInTenMinutes);
      return this.pixelsInTenMinutes;
    },
  },

  methods: {
    getDateTime(date) {
      return dateHelper.getHumanReadDate(date);
    },

    onBlur(direction) {
      //console.log("blur");
    },
    onProgramFocus(program) {
      //console.log("[ GUIDETRAD WEB ] onProgramFocus", program);
      if (program) {
        const { channel, idAsset, startHour, endHour } = program;

        telecentro.tplay.core.epg.obtenerPrograma(channel.dvbTriplet, idAsset, (data) => {
          const { poster, synopsis } = data;
          this.focusedProgram = {
            ...program,
            time: `${startHour} a ${endHour} hs.`,
            poster,
            synopsis,
          };
        });

        program.status = this.$epg.getStatus(program);
        program.fromEpg = this.firstAccess ? false : true;
        this.firstAccess = false;
        this.focusedProgram = {
          ...program,
          time: `${startHour} a ${endHour} hs.`,
        };
      }
    },
    onPageTurn(times) {
      //console.log('onPageTurn', times);
      this.startTime = times.startTime;
      this.endTime = times.endTime;
    },

    changeCursor(show) {
      if (show) {
        document.documentElement.style.cursor = "wait";
      } else {
        document.documentElement.style.cursor = "default";
      }
    },

    findBy(list, value, column) {
      return list.filter(function (item) {
        let name = item[column].toUpperCase();
        return name.includes(value);
      });
    },

    onScrollLeft() {
      //console.log("[ GUIDETRAD WEB ] onScrollLeft");
      this.getNewPrograms(-1);
    },

    onScrollRight() {
      //console.log("[ GUIDETRAD WEB ] onScrollRight");
      this.getNewPrograms(1);
    },

    goToDay(timeStamp) {
      let now = new Date().getTime();

      this.horizontalScrollCounter = parseInt(timeStamp - now);

      //onsole.log('Go To Day', timeStamp, this.focusOnTime, this.horizontalScrollCounter);

      this.focusOnTime = timeStamp;
    },

    getNewPrograms(index) {
      const self = this;
      let now = new Date().getTime();
      if (index === -1) {
        //self.focusOnTime =  self.$refs.guideTraditionalMain.cursorTime - telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000;
        this.horizontalScrollCounter =
          this.horizontalScrollCounter - TIEMPO_SCROLL_HORIZONTAL;
      } else {
        //self.focusOnTime = self.$refs.guideTraditionalMain.cursorTime  + telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS * 1000;
        this.horizontalScrollCounter =
          this.horizontalScrollCounter + TIEMPO_SCROLL_HORIZONTAL;
        // self.focusOnTime =
        //   self.$refs.guideTraditionalMain.cursorTime + TIEMPO_SCROLL_HORIZONTAL;
      }

      self.focusOnTime = now + this.horizontalScrollCounter;
    },

    onScrollToChannel(channel) {
      this.scrolledChannel = channel;
    },

    onResizeChanges() {
      this.pixelsInTenMinutes = guideTradHelpers.calcPixelsInTenMinutes();
    },

    isBlocked(program) {
      return blockAsset(program);
    },

    onLiveRefresh(data) {
      //console.log('LIVE REFRESH',data);
      if (data.status === "on") {
        this.isLive = true;
      } else {
        this.isLive = false;
      }
    },
  },

  beforeDestroy() {
    const body = document.getElementById("tplay");
    this.resizeObserver.unobserve(body);
    document.removeEventListener("scroll", this.onScroll, true);
    document.body.style.overflow = "";
  },
};
</script>
