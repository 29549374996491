/**
 * Obtiene el ancho de la columna de canales de la guia
 * @returns { number } - Tamaño en pixeles de la columna de la guia
 */
const getChannelColumnWidth = () => {
    const columnChannelClass = 'guide-list-programs-item-content';

    const domColumnChannel = window.document.getElementsByClassName(columnChannelClass);

    if(domColumnChannel.length > 0){
        const domColumnChannelWidth = domColumnChannel[0].scrollWidth;
        return domColumnChannelWidth;
    } else {
        return 0;
    }
}

/**
 * Obtiene el ancho del menú
 * @returns { number } - Tamaño en pixeles del ancho del menú
 */
const getAppMenuWidth = () => {
    const menuClass = 'js-menu side-menu';
    const domMenu = window.document.getElementsByClassName(menuClass);

    if(domMenu.length > 0){
        const domMenuWidth = domMenu[0].scrollWidth;
        return domMenuWidth;
    } else {
        return 0;
    }
}


/**
 * Obtiene el tamaño real de pixeles del contenedor de programas de la guia
 * Para ello toma el tamaño total de pantalla y descuenta el ancho del menú y de la columna de canales
 * @returns { number } - Tamaño en pixeles del contenedor de programas
 */
const getContentWidth = () => {
    const profile = telecentro.tplay.core.helpers.imagesHelper.getDeviceProfile();
    //const domMenuWidth = getAppMenuWidth();
    const domColumnChannelWidth = getChannelColumnWidth();

    if(domColumnChannelWidth > 0){
        return profile.realWidth - domColumnChannelWidth;
    } else {
        return profile.realWidth;
    }
}




/**
 * Calcula la cantidad de pixeles en función del perfil y tamaño de pantalla disponibles
 * Para la guia tradicional las condiciones son:
 *  - Cantidad de horas que serán renderizadas por pagina: 3
 *  - Unidad mínima de tiempo que toma como parámetro el componente de guia tradicional: 10min.
 *  - Base de cálculo: 3 horas * 60 minutos / 10 = 18 posiciones por pagina
 * @returns { number } - Cantidad de pixeles en 10 minutos
 */
const calcPixelsInTenMinutes = () => {
    const profile = telecentro.tplay.core.helpers.imagesHelper.getDeviceProfile();
    const contentWidth = getContentWidth();
    const positionsPerPage = (3 * 60 / 10);

    return contentWidth / positionsPerPage;
}

/**
 *
 */
const calcTimeLineLeftPixels = () => {
    return getChannelColumnWidth();
}





export { calcPixelsInTenMinutes, calcTimeLineLeftPixels }
